@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "League Spartan", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  position: relative !important;
  height: 100%;
}

html {
  background-color: #ff6a6a !important;
  overflow-x: hidden !important;
  max-width: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-transition {
  height: 160px !important;
}

@media only screen and (max-width: 768px) {
  .text-transition {
    height: 80px !important;
  }
}
